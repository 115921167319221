import React from 'react';
import Widget from '../verticalWidget';

const CategoryWidget1 = (props) => {
  return (
    <div>
      <Widget cardType={1} {...props} />
    </div>
  );
};

export default CategoryWidget1;
